import {inject, Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "../_services/translate.service";
import {DatePipe} from "@angular/common";
import {getFormatDate} from "@config/config";
import {FlowCustomFormatDate} from "@app/shared/_interfaces/flows";

@Pipe({
  name: 'localizedDate',
  pure: false,
  standalone: true
})
export class LocalizedDatePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  transform(value:  Date | string | number, format?: string): any {

    const curLanguage = this.translateService.getCurrentLanguage();

    const datePipe = new DatePipe(
      curLanguage
    );


    format = getFormatDate(format as FlowCustomFormatDate, curLanguage);

    return datePipe.transform(value, format);
  }

}
