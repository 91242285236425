import {inject} from '@angular/core';
import {HttpErrorResponse, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {throwError} from 'rxjs';
import {TranslateService} from '../_services/translate.service';
import {catchError} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {SKIP_AUTHENTICATION, SKIP_MODAL_ERROR} from '../_models/auth';
import {Router} from '@angular/router';
import {AuthenticationService} from "../_services/authentication.service";
import {JwtSwal} from "../_interfaces/jwt";
import {environment} from "@environments/environment";

export const AuthorizationInterceptor = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
) => {

  const translateService = inject(TranslateService);
  const router = inject(Router);
  const authenticationService = inject(AuthenticationService);

  const getErrorMessage = (title: string, err: HttpErrorResponse) => {

    const translatedTitle = translateService.translate(title);

    if (environment.production) {
      return translatedTitle;
    }

    return `
        <p><strong>${translatedTitle}</strong></p>
        <p><strong>${translateService.translate('jwt_error_url')}</strong>: ${err.url}</p>
        <p><strong>${translateService.translate('jwt_error_status')}</strong>: ${err.status}</p>
        <p><strong>${translateService.translate('jwt_error_full_details')}</strong>:</p>
        <p><textarea style="width:100%" rows="10" readonly>${JSON.stringify(err)}</textarea></p>
    `;
  };

  const swal = (data: JwtSwal) => {
    if (data.type === 'swal') {
      return Swal.fire(data.params.title, data.params.message, data.params.severity);
    }
    return new Promise((resolve, reject) => resolve);
  }


  const skipModalError = (req: HttpRequest<unknown>, status: number | 'all') => {
    return req.context.get(SKIP_MODAL_ERROR)
      .some(_status => status === _status);
  }

  req = req.clone({
    setHeaders: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });

  if (!req.context.get(SKIP_AUTHENTICATION)) {
    const user = authenticationService.currentUserValue;
    req = req.clone({
      setHeaders: {
        'authkey': !environment.production && user.Authkey != null ?
          user.Authkey :
          authenticationService.getCookieValue('authkey')
      }
    });
  }

  return next(req).pipe(
    catchError(err => {
      const type = 'swal';

      if (err instanceof HttpErrorResponse && !skipModalError(req, 'all')) {

        let error: { message: string, status: number } = {message: '', status: err.status};

        if (typeof err.error?.error === 'string') {
          error.message = err.error.error;
        }

        if (err.error?.error?.message) {
          error.message = err.error.error.message;
        }

        if (err?.error?.data?.reason) {
          error.message = err.error.data.reason;
        }

        if (!error.message) {
          error.message = 'internal_server_error';
        }

        const {message} = error;

        if (!skipModalError(req, err.status)) {
          switch (err.status) {
            case 401:
              if (authenticationService.isLogged()) {
                router.navigate(['login'], {
                  queryParams: {
                    err: 'authentication_expired'
                  }
                });
              }
              break;
            case 400:
              swal({
                type,
                params: {
                  title: translateService.translate('error') + ' :(',
                  'message': getErrorMessage(message, err),
                  severity: 'error'
                }
              });
              break;
            case 404:
              if (message) {
                swal({
                  type,
                  params: {
                    title: translateService.translate('error') + ' :(',
                    message,
                    severity: 'error'
                  }
                });
              } else {
                if (!environment.production) {
                  swal({
                    type,
                    params: {
                      title: translateService.translate('error') + ' :(',
                      'message': getErrorMessage('jwt_url_not_exists', err),
                      severity: 'error'
                    }
                  });
                }
              }
              break;
            case 413:
              swal({
                type,
                params: {
                  title: translateService.translate('error') + ' :(',
                  'message': getErrorMessage('file_too_large', err),
                  severity: 'error'
                }
              });
              break;
            case  500:
              swal({
                type,
                params: {
                  title: translateService.translate('error') + ' :(',
                  message: getErrorMessage('server_problem_please_retry', err),
                  severity: 'error'
                }
              });
              break;
            case 504:
              swal({
                type,
                params: {
                  title: translateService.translate('error') + ' :(',
                  message: getErrorMessage('server_problem_gateway_timeout', err),
                  severity: 'error'
                }
              });
              break;
            case 0:
              swal({
                type,
                params: {
                  title: translateService.translate('error') + ' :(',
                  message: getErrorMessage('server_problem_unknown_error', err),
                  severity: 'error'
                }
              });
              break;
            default:
              swal({
                type,
                params: {
                  title: translateService.translate('error') + ' :(',
                  'message': translateService.translate(err.error?.message),
                  severity: 'error'
                }
              });
              break;
          }
        }
      }
      return throwError(() => err);
    })
  );

}
