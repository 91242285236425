export const environment = {
  production: false,
  apiUrl: 'https://api.dev.flows.emailchef.com/index.php',
  emailchefAuthkey: null,
  emailchefEndpointUrl: "https://andreadb.dev.emailchef.com",
  showDebugInfo: true,
  canvasRightClick: false,
  type: 'dev',
  hotjar: {
    id: 5056122,
    sv: 6,
    account_ids: []
  }
};
