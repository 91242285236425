import {Component, inject, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {RouterOutlet} from "@angular/router";
import {HotjarService} from "@app/shared/_services/hotjar.service";
import {EmailchefService} from "@app/shared/_services/emailchef.service";
import {filter, map, tap} from "rxjs";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  standalone: true,
  imports: [
    RouterOutlet
  ]
})
export class AppComponent implements OnInit {

  private primengConfig = inject(PrimeNGConfig);
  private hotjarService = inject(HotjarService);
  private emailchefService = inject(EmailchefService);

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.zIndex = {
      ...this.primengConfig.zIndex,
      modal: 1105
    }
    this.emailchefService.getAccountInfo()
      .pipe(
        map(res => res.data)
      ).subscribe({
      next: ({account_id}) => {
        if (environment?.hotjar?.id && environment?.hotjar?.sv) {
          const account_ids = environment?.hotjar?.account_ids || [];
          if (account_ids.includes(+account_id)){
            this.hotjarService.initHotjar();
          }
        }
      }
    });
  }
}
