export type TimeUnit = |
  'minutes' |
  'hours' |
  'days' |
  'weeks';

type SecondsPerUnit = Record<TimeUnit, number>;

export const secondsPerUnit: SecondsPerUnit = {
  minutes: 60,
  hours: 3600,
  days: 86400,
  weeks: 604800
};

export const getClosestTimeUnit = (
  seconds: number
) => {
  const units = Object.keys(secondsPerUnit) as TimeUnit[];

  let closestUnit = units[0];
  let closestValue = seconds / secondsPerUnit[closestUnit];

  units.forEach(u => {
    if (u !== closestUnit) {
      const value = seconds / secondsPerUnit[u];
      if (Math.floor(value) === value) {
        closestUnit = u;
        closestValue = value;
      }
    }
  });

  return {
    unit: closestUnit,
    value: closestValue
  }

}


export const calculateSeconds = (value: number, unit: TimeUnit) => value * secondsPerUnit[unit];
