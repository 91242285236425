import {HttpContext, HttpContextToken} from "@angular/common/http";

export const SKIP_MODAL_ERROR = new HttpContextToken<(number | 'all')[]>(() => [])

export function skipModalError(statuses: (number | 'all')[]) {
  return new HttpContext().set(SKIP_MODAL_ERROR, statuses);
}

export const SKIP_AUTHENTICATION = new HttpContextToken<boolean>(() => false);

export function skipAuthentication() {
  return new HttpContext().set(SKIP_AUTHENTICATION, true);
}

export class Auth {
  Authkey: string;
}
