import {inject, Injectable} from '@angular/core';
import * as dot from 'dot-object';
import {PrimeNGConfig} from 'primeng/api';
import {User} from "../_interfaces/user";
import {getClosestTimeUnit} from "@app/shared/_helpers/time";
import {I18NMapParams} from "@app/shared/_interfaces/i18n";
import {replaceText} from "@app/shared/_helpers/string";

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private translations: Array<object> = [];
  private supportedLanguages: Array<string> = [];
  private currentLanguage: string;
  private fallbackLanguage: string;
  private config = inject(PrimeNGConfig);

  getSupportedLanguages(): Array<string> {
    return this.supportedLanguages;
  }

  isSupportedLanguage(lang: string): boolean {
    return this.supportedLanguages.indexOf(lang) > -1;
  }

  getFallbackLanguage(): string {
    return this.fallbackLanguage;
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }

  setFallbackLanguage(lang: string): void {
    if (!this.isSupportedLanguage(lang)) {
      throw new Error(
        `${lang} isn't supported language.`
      );
    }
    this.fallbackLanguage = lang;
  }

  setTranslation(lang: string, t: any) {
    if (!this.isSupportedLanguage(lang)) {
      throw new Error(
        `${lang} isn't supported language.`
      );
    }
    this.translations[lang] = t;
  }

  setSupportedLanguages(langs: string[]) {
    this.supportedLanguages = langs;
  }

  setActiveLanguage(lang: string) {

    if (!this.fallbackLanguage) {
      throw new Error('Fallback language should be configured');
    }

    if (!this.isSupportedLanguage(lang)) {
      lang = this.fallbackLanguage;
    }

    this.currentLanguage = lang;
    //setLocale(this.currentLanguage);

    const translationLang = 'primeng' in this.translations[this.currentLanguage] ? this.currentLanguage : this.fallbackLanguage

    this.config.setTranslation(this.translations[translationLang]['primeng']);

  }

  private has(key: string) {
    if (this.translations.hasOwnProperty(this.currentLanguage) && this.translations[this.currentLanguage].hasOwnProperty(key)) {
      return true;
    } else if (this.translations.hasOwnProperty(this.fallbackLanguage) && this.translations[this.fallbackLanguage].hasOwnProperty(key)) {
      return true;
    }
    return false;
  }

  getPicmo(): Record<string, string> {
    return this.get('picmo');
  }

  private get(key: string) {

    if (key === 'primeng') {
      return 'primeng';
    }

    if (this.translations.hasOwnProperty(this.currentLanguage) && this.translations[this.currentLanguage].hasOwnProperty(key)) {
      return this.translations[this.currentLanguage][key];
    } else if (this.translations.hasOwnProperty(this.fallbackLanguage) && this.translations[this.fallbackLanguage].hasOwnProperty(key)) {
      return this.translations[this.fallbackLanguage][key];
    }
    return key;
  }

  switchTranslate(wishKey: string, defaultKey: string, mapKeys?: I18NMapParams) {
    return this.translate(this.has(wishKey) ? wishKey : defaultKey, mapKeys);
  }

  fancyTime(seconds: number) {
    const {value, unit} = getClosestTimeUnit(seconds);
    let text_unit= value > 1 ? unit : unit.substring(0, unit.length - 1);
    return `${value} ${this.translate(`time_${text_unit}`)}`;
  }

  translate(key: string, mapKeys?: I18NMapParams): string {

    const errors = [];

    if (!this.currentLanguage) {
      errors.push(`currentLanguage is null currentLanguage=${this.currentLanguage} key=${key}`);
    }
    if (!this.fallbackLanguage) {
      errors.push(`fallbackLanguage is null fallbackLanguage=${this.fallbackLanguage} key=${key}`);
    }

    if (errors.length > 0) {
      return key;
    }

    let res = this.get(key);

    if (mapKeys && typeof res === 'string') {
      res = replaceText(res, mapKeys);
    }

    return res;
  }

  translateObject(data: object, involvedKeys: string[], mapKeys?: I18NMapParams): object {
    let newData = {...data};


    for (let invKey of involvedKeys) {
      const curVal = dot.pick(invKey, newData);
      if (curVal) {
        dot.set(invKey, this.translate(curVal, mapKeys), newData);
      }
    }

    return newData;
  }

  getTranslations(): Array<object> {
    return this.translations;
  }

  getCurrentTranslations(): Array<object> {
    return this.translations[this.currentLanguage];
  }

  reloadByConfig(res: User.Config) {
    const {translations, config} = res;
    this.setSupportedLanguages([...config.supported_languages, config.current_language]);

    this.setTranslation(
      config.current_language,
      translations[config.current_language]
    );


    this.setTranslation(
      config.fallback_language,
      translations[config.fallback_language]
    );

    this.setFallbackLanguage(config.fallback_language);
    this.setActiveLanguage(config.current_language);
  }

}
